<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col class="flex justify-between">
          <div class="flex align-center justify-start h50">
            <el-tooltip class="item" effect="dark" content="打印订单" placement="bottom">
              <el-button v-print="printObj" type="primary" size="small">打印</el-button>
            </el-tooltip>
          </div>
          <div class="flex align-center justify-end header-search-box">
            <div class="block" style="margin-right:10px;">
              <el-input placeholder="请输入小区名" v-model="queryInfo.condition.villageName" clearable>
              </el-input>
            </div>
            <div class="block" style="margin-right:10px;">
              <span class="demonstration"></span>
              <el-date-picker v-model="time" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <el-button slot="append" icon="el-icon-search" class="textblue searchBtn" @click="search()"></el-button>
          </div>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table id="table" :data="tableData" border fit :height="height" highlight-current-row style="width: 100%"
        @sort-change="sortChange">
        <el-table-column label="序号" align="center" type="index" width="50" />
        <el-table-column label="打印状态" align="center" prop="打印状态">
          <template slot-scope="scope">
            <span v-if="scope.row.print === '1'">已打印</span>
            <span v-if="
              scope.row.print === '0' ||
                scope.row.print === undefined ||
                scope.row.print === ''
            ">未打印</span>
          </template>
        </el-table-column>
        <el-table-column label="供应商名称" align="center" prop="供应商名称" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.supplierName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="用户名称" align="center" prop="用户名称" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.memberName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单号" align="center" prop="订单号" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.orderNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单金额" align="center" prop="订单金额" min-width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.orderAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付状态" align="center" prop="支付状态" min-width="80">
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.payStatus }}</span> -->
            <span v-if="scope.row.payStatus === '1'">已支付</span>
            <span v-if="scope.row.payStatus === '0'">未支付</span>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" align="center" prop="订单状态" min-width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus === '1'">已支付</span>
            <span v-if="scope.row.orderStatus === '2'">已发货</span>
            <span v-if="scope.row.orderStatus === '3'">已完成</span>
          </template>
        </el-table-column>
        <el-table-column label="小区名称" align="center" prop="小区名称" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.villageName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="配送点名称" align="center" prop="配送点名称" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.deliveryName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="创建时间" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.createDt | dataFormat }}</span>
          </template>
        </el-table-column>

        <el-table-column label="收货人" align="center" prop="收货人" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.receiver }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货人电话" align="center" prop="收货人电话" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.receiverPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货人地址" align="center" prop="收货人地址" min-width="450">
          <template slot-scope="scope">
            <span>{{ scope.row.receiverAddress }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="bottom">
              <i class="el-icon-edit textblue f16" @click="gotoDetail(scope.row.id, scope.row.orderNumber)"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
              <i class="el-icon-delete textblue f16 ml20" @click="del(scope.row.id)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
    </el-card>
    <!-- 打印的模板 -->
    <div id="print" class="printStyle">
      <div v-for="(item,index) in listData" :key="item.activityCode">
          <div class="print_container">
            <h1>智慧社区小程序订单</h1>
            <span>**************************</span>
            <div class="section1">
              <h3>智慧社区</h3>
              <h3>在线支付</h3>
            </div>
            <span>**************************</span>
            <div class="section2">
              <!-- <label>打印状态：{{print=='1'?'已打印':'未打印'}}</label> -->
              <label>序号：{{index+1}}</label>
              <label>供应商：{{item.supplierName}}</label>
              <!-- <label>用户名称：{{item.memberName}}</label> -->
              <label>订单号：{{item.orderNumber}}</label>
              <label>订单金额：{{item.orderAmount}}</label>
              <label>支付状态：{{item.payStatus=='1'?'已支付':'未支付'}}</label>
              <label>订单状态：{{item.orderStatus=='1'?'已支付':(item.orderStatus == '2'?'已发货':'已完成')}}</label>
              <label><b>配送点：{{item.deliveryName}}</b></label>
              
            </div>
            <span>**************************</span>
            <div class="section3">
              <label>创建时间：{{item.createDt | dataFormat}}</label>
              <!-- <label>微信单号：{{item.backNo}}</label> -->
            </div>
            <span>**************************</span>
            <div class="section5">
              <label><b>收货人：{{item.receiver}}</b></label>
              <label><b>电话：{{item.receiverPhone}}</b></label>
              <label><b>地址：{{item.receiverAddress}}</b></label>
            </div>
            <span>**************************</span>
          </div>
        </div>
      <!-- <el-table id="table" :data="tableData" border fit highlight-current-row style="width: 100%"
        @sort-change="sortChange">
        <el-table-column label="序号" align="center" type="index" width="50" />
        <el-table-column label="打印状态" align="center" prop="打印状态">
          <template slot-scope="scope">
            <span v-if="scope.row.print === '1'">已打印</span>
            <span v-if="
              scope.row.print === '0' ||
                scope.row.print === undefined ||
                scope.row.print === ''
            ">未打印</span>
          </template>
        </el-table-column> -->

       

        <!-- <el-table-column label="供应商名称" align="center" prop="供应商名称">
          <template slot-scope="scope">
            <span>{{ scope.row.supplierName }}</span>
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="用户名称" align="center" prop="用户名称">
          <template slot-scope="scope">
            <span>{{ scope.row.memberName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单号" align="center" prop="订单号">
          <template slot-scope="scope">
            <span>{{ scope.row.orderNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单金额" align="center" prop="订单金额">
          <template slot-scope="scope">
            <span>{{ scope.row.orderAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付状态" align="center" prop="支付状态">
          <template slot-scope="scope">
            <span v-if="scope.row.payStatus === '1'">已支付</span>
            <span v-if="scope.row.payStatus === '0'">未支付</span>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" align="center" prop="订单状态">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus === '1'">已支付</span>
            <span v-if="scope.row.orderStatus === '2'">已发货</span>
            <span v-if="scope.row.orderStatus === '3'">已完成</span>
          </template>
        </el-table-column>
        <el-table-column label="配送点名称" align="center" prop="配送点名称">
          <template slot-scope="scope">
            <span>{{ scope.row.deliveryName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createDt | dataFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微信单号" align="center" prop="微信单号">
          <template slot-scope="scope">
            <span>{{ scope.row.backNo }}</span>
          </template>
        </el-table-column>

        <el-table-column label="收货人" align="center" prop="收货人">
          <template slot-scope="scope">
            <span>{{ scope.row.receiver }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货人电话" align="center" prop="收货人电话">
          <template slot-scope="scope">
            <span>{{ scope.row.receiverPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货人地址" align="center" prop="收货人地址">
          <template slot-scope="scope">
            <span>{{ scope.row.receiverAddress }}</span>
          </template>
        </el-table-column> -->
      <!-- </el-table> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      searchInput: "",
      tableData: [],
      listData:[],
      time: [],
      loading: true,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          id: "",
          deliveryCode: localStorage.getItem("deliveryCode"),
          supplierCode: localStorage.getItem("supplierCode"),
          //   payStatus:"1", //查询已支付的订单
          startTime: "",
          endTime: "",
          villageName: ""
        }
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: ""
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      printObj: {
        id: "print",
        popTitle: "支付订单"
      }
    };
  },
  created() {
    this.queryInfo.currPage = this.$route.query.currPage;
    this.queryInfo.pageSize = this.$route.query.pageSize;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
    console.log('this.tableData',this.tableData)
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  watch: {
    time(newV) {
      if (newV) {
        this.queryInfo.condition.startTime = this.$options.filters[
          "dataFormat"
        ](newV[0]);
        this.queryInfo.condition.endTime = this.$options.filters["dataFormat"](
          newV[1]
        );
      } else {
        (this.queryInfo.condition.startTime = ""),
          (this.queryInfo.condition.endTime = "");
      }
    }
  },
  methods: {
    //条件查询
    search() {
      this.queryInfo.condition.id = this.searchInput;
      this.getListData();
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() { },
    //跳转到详情
    gotoDetail(id, orderNumber) {
      console.log(orderNumber);
      var that = this;
      this.$router.push({
        path: "/supplierOrder/details",
        query: {
          id: id,
          orderNumber: orderNumber,
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize
        }
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/supplierOrder/delete", { id: id })
          .then(function (response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true
            });
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      that.$http
        .post("/supplierOrder/list", that.queryInfo)
        .then(function (response) {
          console.log('获取列表',response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
            that.listData = response.data.data.data;
            that.tableData.map(v => {
              v.memberName = decodeURI(v.memberName);
              return v;
            });
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}

.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;

    .el-select>.el-input {
      width: 200px;
    }

    .header-search-box .header-search-input {
      width: 325px;
    }

    input::placeholder {
      font-size: 12px;
    }

    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }

    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: -20px;
    }
  }
}

.printStyle {
  display: none;
}

@media print {
  .printStyle {
    display: block;
    height: auto;
    overflow: hidden;
  }
}

// * {
//   padding: 0;
//   margin: 0;
//   font-family: "Microsoft Yahei", BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
// }

h1 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clearfix {
  clear: both;
}

ul {
  list-style: none;
}

.print_container {
  padding: 20px;
  width: 188px;
}

.section1 {}

.section2 label {
  display: block;
}

.section3 label {
  display: block;
}

.section4 {}

.section4 .total label {
  display: block;
}

.section4 .other_fee {
  border-bottom: 1px solid #DADADA;
}

.section5 label {
  display: block;
}
</style>
